import React from 'react';
import TableRow from '~/components/Table/TableRow';
import TableCell from '~/components/Table/TableCell';
import Answers from './Answers';
import { format } from 'date-fns'

const LANGUAGE = {
  tw: '中文',
  en: '英語',
  vn: '越南語',
  id: '印度語',
};

const getScore = answers => {
  return answers.reduce((a, b) => a + b, 0);
};

export default ({ answer }) => {
  return (
    <TableRow>
      <TableCell>{format(new Date(answer.get('createTime')), 'yyyy年MM月dd日 HH點mm分')}</TableCell>
      <TableCell><b>{answer.get('bedNo')}</b></TableCell>
      <TableCell>{LANGUAGE[answer.get('language')]}</TableCell>
      <Answers answers={answer.get('answers')} />
      <TableCell>
        <b>{getScore(answer.get('answers'))}</b>
      </TableCell>
      <TableCell>{answer.get('remark')}</TableCell>
    </TableRow>
  );
};
