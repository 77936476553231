import { connect } from 'react-redux';
import ReportScreen from './view';
import { logoutAction } from '~/actions/authActions';
import { getReportAction, syncAnswerAction } from '~/actions/reportActions';

const mapStateToProps = ({ report, auth }) => ({
  token: auth.get('token'),
  records: report.get('docs'),
  pagination: report.get('pagination').toJS(),
});

const mapDispatchToProps = dispatch => ({
  handleGetReport: payload => {
    dispatch(getReportAction(payload));
  },
  handleLogout: payload => {
    dispatch(logoutAction(payload));
  },
  handleSyncData: () => {
    dispatch(syncAnswerAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
