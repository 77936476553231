import { connect } from 'react-redux';
import AccountsScreen from './view';
import { getAccountsAction, updateAccountAction } from '~/actions/userActions';

const mapStateToProps = ({ user, auth }) => ({
  records: user.get('docs'),
  pagination: user.get('pagination').toJS(),
});

const mapDispatchToProps = dispatch => ({
  handleGetAccounts: payload => {
    dispatch(getAccountsAction(payload));
  },
  handleUpdateAccount: payload => {
    dispatch(updateAccountAction(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsScreen);
