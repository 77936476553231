import React from 'react';
import './style.scss';

const GridItem = ({ children, ...props }) => {
  return (
    <div className='grid-item' {...props}>
      {children}
    </div>
  );
};

const GridContainer = ({ children, ...props }) => {
  return (
    <div className='grid-container' {...props}>
      {children}
    </div>
  );
};

const Grid = ({ container, item, ...props }) => {
  if (container) return <GridContainer {...props} />;
  return <GridItem {...props} />;
};

export default Grid;
