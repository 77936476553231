import React from 'react';
import './style.scss';

const TableBody = ({ children }) => {
  return (
    <div className='table-body'>
      {children}
    </div>
  )
}

export default TableBody;
