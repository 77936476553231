import React from 'react';
import Button from '~/components/Button';
import TableRow from '~/components/Table/TableRow';
import TableCell from '~/components/Table/TableCell';
import { format } from 'date-fns'

const STATUS = {
  0: '停用',
  1: '啟用',
};

export default ({ account, onDialogOpen }) => {
  const disabled = account.get('account') === 'admin';
  return (
    <TableRow>
      <TableCell>{account.get('account')}</TableCell>
      <TableCell>{account.get('username')}</TableCell>
      <TableCell>{STATUS[account.get('status')]}</TableCell>
      <TableCell>{format(new Date(account.get('createTime')), 'yyyy年MM月dd日 HH點mm分')}</TableCell>
      <TableCell><Button text='編輯' onClick={onDialogOpen} disabled={disabled}/></TableCell>
    </TableRow>
  );
};
