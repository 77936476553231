import React from 'react';
import './style.scss';

const TableHeader = ({ children }) => {
  return (
    <div className='table-header'>
      {children}
    </div>
  )
}

export default TableHeader;
