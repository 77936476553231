import React from 'react';
import Chart from '~/components/Chart';

const labels = ['5月5日', '5月6日', '5月7日', '5月8日', '5月9日', '5月10日'];
const data = {
  labels: labels,
  datasets: [
    {
      label: '答題人數',
      backgroundColor: '#4AA9DB',
      borderColor: '#4AA9DB',
      data: [0, 10, 5, 2, 20, 30, 45],
    },
    {
      label: '合格人數',
      backgroundColor: '#88d839',
      borderColor: '#88d839',
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};

const config = {
  type: 'bar',
  data,
  options: { plugins: {}, responsive: true },
};

const AnswerChart = () => {
  return (
    <div className='chart-container'>
      <Chart name='answer' config={config} />
    </div>
  );
};

export default AnswerChart;
