import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const ChartS = ({ name, config }) => {
  const chartId = `chart_${name}`;

  useEffect(() => {
    new Chart(document.getElementById(chartId).getContext('2d'), config);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return <canvas id={chartId}></canvas>;
};

export default ChartS;
