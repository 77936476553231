import React from 'react';
import TableHeader from '~/components/Table/TableHeader';
import TableCell from '~/components/Table/TableCell';

export default () => {
  return (
    <TableHeader>
      <TableCell>施測日期</TableCell>
      <TableCell>病室床號</TableCell>
      <TableCell>語言</TableCell>
      <TableCell>題 1</TableCell>
      <TableCell>題 2</TableCell>
      <TableCell>題 3</TableCell>
      <TableCell>題 4</TableCell>
      <TableCell>題 5</TableCell>
      <TableCell>題 6</TableCell>
      <TableCell>題 7</TableCell>
      <TableCell>題 8</TableCell>
      <TableCell>題 9</TableCell>
      <TableCell>題 10</TableCell>
      <TableCell>總分</TableCell>
      <TableCell>備註</TableCell>
    </TableHeader>
  );
};
