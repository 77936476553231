import { connect } from 'react-redux';
import ErrorScreen from './view';

const mapStateToProps = ({ account }) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);
