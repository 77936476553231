import React from 'react';
import './style.scss';

const TableRow = ({ children }) => {
  return (
    <div className='table-row'>
      {children}
    </div>
  )
}

export default TableRow;
