import React from 'react';
import Icon from '~/components/Icon';
import Grid from '~/components/Grid';
import InfoCard from './components/InfoCard';
import Panel from '~/components/Panels/Panel';
import PanelHeader from '~/components/Panels/PanelHeader';
import PanelBody from '~/components/Panels/PanelBody';
import VisitChart from './components/VisitChart';
import AnswerChart from './components/AnswerChart';

const ChartTitle = ({ icon, title }) => {
  return <div className="chart-title"><Icon icon={icon} /><span>{title}</span></div>
}

const Dashboard = () => {
  return (
    <div>
      <Grid container>
        <Grid item>
          <InfoCard
            type='today'
            title='今日新增筆數'
            subtitle='比昨日多出 10 筆'
            content='20'
            icon={<Icon icon='dove' />}
          />
        </Grid>
        <Grid item>
          <InfoCard
            type='total'
            title='累計筆數'
            subtitle='系統累積資料筆數'
            content='10'
            icon={<Icon icon='calendar' />}
          />
        </Grid>
        <Grid item>
          <InfoCard
            type='visit'
            title='今日拜訪人數'
            subtitle='點擊進入網站次數'
            content='0'
            icon={<Icon icon='door-open' />}
          />
        </Grid>
        <Grid item>
          <InfoCard
            type='totalVisit'
            title='累計人次'
            subtitle='系統累積點擊人次'
            content='0'
            icon={<Icon icon='cubes' />}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Panel>
            <PanelHeader title={<ChartTitle icon="chart-line" title='近一週網站拜訪人數' />} />
            <PanelBody>
              <VisitChart />
            </PanelBody>
          </Panel>
        </Grid>
        <Grid item>
          <Panel>
            <PanelHeader title={<ChartTitle icon="chart-bar" title='近一週答題人數'/>} />
            <PanelBody>
              <AnswerChart />
            </PanelBody>
          </Panel>
        </Grid>
      </Grid>
      <Grid container>

      </Grid>
    </div>
  );
};

export default Dashboard;
