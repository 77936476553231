import { combineReducers } from 'redux';
import auth from './authReducer';
import report from './reportReducer';
import user from './userReducer';

export const reducer = combineReducers({
  auth,
  user,
  report,
});

export default reducer;
