import React from 'react';
import classnames from 'classnames';
import isNull from 'lodash/isNull';
import Icon from '../Icon';
import './style.scss';

const PreButton = ({ disabled, onChange, prevPage }) => {
  return (
    <button
      className='pre-button'
      onClick={onChange(prevPage)}
      disabled={disabled}>
      <Icon icon='angle-left' />
    </button>
  );
};

const NextButton = ({ disabled, onChange, nextPage }) => {
  return (
    <button
      className='next-button'
      onClick={onChange(nextPage)}
      disabled={disabled}>
      <Icon icon='angle-right' />
    </button>
  );
};

const NumberButton = ({ onChange, page, currentPage }) => {
  return (
    <button
      className={classnames('page-button', {
        active: currentPage === page,
      })}
      onClick={onChange(page)}>
      {page}
    </button>
  );
};

const RestDot = () => {
  return <span className='rest-dot'>...</span>;
};

const Pages = ({ pages, onChange, currentPage }) => {
  const buttons = [];
  const maxButtonCounts = pages >= 10 ? 10 : pages;
  const hasNextRestPages = currentPage + 4 < pages;
  const hasPreRestPages = currentPage - 4 > 0;
  let minPage = hasPreRestPages ? currentPage - 4 : 1;
  minPage = !hasNextRestPages ? pages - 9 : minPage;

  if (hasPreRestPages) {
    buttons.push(<RestDot />);
  }

  for (let page = minPage; page < minPage + maxButtonCounts; page++) {
    buttons.push(
      <NumberButton
        key={`pagination_${page}`}
        page={page}
        onChange={onChange}
        currentPage={currentPage}
      />
    );
  }

  if (hasNextRestPages) {
    buttons.push(<RestDot />);
  }

  return buttons;
};

const TotalContent = ({ content }) => {
  return <div className='total-content'>{`共 ${content} 筆`}</div>;
};

const handlePageChange = onChange => param => () => {
  if (isNull(param)) return;
  onChange(param);
};

const Pagination = ({
  page,
  totalPages,
  totalDocs,
  hasNextPage,
  hasPrevPage,
  limit,
  prevPage,
  nextPage,
  onChange,
}) => {
  const onPageChange = handlePageChange(onChange);

  return (
    <div className='pagination-box'>
      <TotalContent content={totalDocs} />
      <PreButton
        disabled={!hasPrevPage}
        onChange={onPageChange}
        prevPage={prevPage}
      />
      <Pages pages={totalPages} currentPage={page} onChange={onPageChange} />
      <NextButton
        disabled={!hasNextPage}
        onChange={onPageChange}
        nextPage={nextPage}
      />
    </div>
  );
};

export default Pagination;
