import React from 'react';
import './style.scss';

const Avatar = ({ onClick }) => {
  return (
    <div className='avatar-box' onClick={onClick}>
      <div className='avatar'>

      </div>
    </div>
  )
}

const UserName = ({ content = '' }) => {
  return <div className='username'>{`您好, ${content.toUpperCase()}`}</div>;
};

const Navigation = ({ username }) => {
  return (
    <div className='navigation-container'>
      <div className='navigation-bar'>
        <UserName content={username} />
        <Avatar />
      </div>
    </div>
  );
};

export default Navigation;
