import React from 'react';
import './style.scss';

export default ({label, value, ...props}) => {
  return (
    <div className='form-group'>
      <label>{label}</label>
      <div className='label-text'>{value}</div>
    </div>
  )
}
