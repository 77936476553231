import React, { useEffect } from 'react';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import Pagination from '~/components/Pagination';
import Container from '~/components/Container';
import TableBody from '~/components/Table/TableBody';
import Table from '~/components/Table/Table';
import Panel from '~/components/Panels/Panel';
import PanelBody from '~/components/Panels/PanelBody';
import PanelHeader from '~/components/Panels/PanelHeader';
import './style.scss';
import Button from '~/components/Button';

export default ({ records, pagination, handleSyncData, handleGetReport }) => {
  useEffect(() => {
    handleGetReport({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = page => {
    handleGetReport({ page });
  };

  return (
    <Container>
      <Panel>
        <PanelBody>
          <div className='search-bar'>
            <Button text='同步資料' onClick={handleSyncData} />
          </div>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader title='答題列表' />
        <PanelBody>
          <Table>
            <TableHeader />
            <TableBody>
              {records.map((answer, index) => {
                return <TableRow key={`answer_${index}`} answer={answer} />;
              })}
            </TableBody>
          </Table>
        </PanelBody>
        <Pagination {...pagination} onChange={onPageChange} />
      </Panel>
    </Container>
  );
};
