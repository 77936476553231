export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

export const GET_CSV = 'GET_CSV';
export const GET_CSV_SUCCESS = 'GET_CSV_SUCCESS';
export const GET_CSV_ERROR = 'GET_CSV_ERROR';

export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';

export const SYNC_ANSWERS = 'SYNC_ANSWERS';
export const SYNC_ANSWERS_ERROR = 'SYNC_ANSWERS_ERROR';
export const SYNC_ANSWERS_SUCCESS = 'SYNC_ANSWERS_SUCCESS';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';

export const GET_VISIT_REPORT = 'GET_VISIT_REPORT';
export const GET_VISIT_REPORT_ERROR = 'GET_VISIT_REPORT_ERROR';
export const GET_VISIT_REPORT_SUCCESS = 'GET_VISIT_REPORT_SUCCESS';
