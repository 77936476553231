import React from 'react';
import Container from '~/components/Container';
import Panel from '~/components/Panels/Panel';
import PanelBody from '~/components/Panels/PanelBody';

const AnalyticScreen = () => {
  return (
    <Container>
      <Panel>
        <PanelBody>

        </PanelBody>
      </Panel>
    </Container>
  )
}

export default AnalyticScreen;
