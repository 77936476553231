import React from 'react';
import './style.scss'

const PanelHeader = ({ title, subtitle }) => {
  return (
    <div className='panel-header'>
      <span className='panel-header-title'>{title}</span>
      <span className='panel-header-subtitle' >{subtitle}</span>
    </div>
  )
}

export default PanelHeader;
