import { connect } from 'react-redux';
import DashboardScreen from './view';
import './style.scss';

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.get('isAuth')
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
