import * as types from '~/constants/actionTypes';

export const getReportAction = payload => ({
  type: types.GET_REPORT,
  payload,
});

export const getCSVAction = payload => ({
  type: types.GET_CSV,
  payload,
});

export const syncAnswerAction = payload => ({
  type: types.SYNC_ANSWERS,
  payload,
});
