import React, { Fragment } from 'react';
import Icon from '~/components/Icon';
import TableCell from '~/components/Table/TableCell';

const TrueItem = () => (
  <TableCell>
    <Icon icon='dot-circle' className='correct'/>
  </TableCell>
);

const FalseItem = () => (
  <TableCell>
    <Icon icon='dot-circle' className='incorrect' />
  </TableCell>
);

export default ({ answers }) => (
  <Fragment>
    {answers.map((answer, index) => {
      return answer ? (
        <TrueItem key={`point_${index}`} />
      ) : (
        <FalseItem key={`point_${index}`} />
      );
    })}
  </Fragment>
);
