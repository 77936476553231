import * as types from '~/constants/actionTypes';
import { fromJS } from 'immutable';
import { authState } from './initState';
import { saveLoginUser } from '~/store/localStorage';

const loginSuccess = (auth, payload) => {
  const { token, account, username } = payload;
  const loginUser = { token, account, username, isAuth: true };
  saveLoginUser(loginUser);
  return auth.merge(fromJS(loginUser));
};

export default function reducer(auth = authState, { type, payload }) {
  switch (type) {
    case types.LOGIN_SUCCESS:
      return loginSuccess(auth, payload);
    case types.LOGIN:
    case types.LOGIN_ERROR:
    default:
      return auth;
  }
}
