import React, { Fragment } from 'react';
import Menu from '../Menu';
import Navigation from '../Navigation';
import './style.scss';

const LayoutWrapper = ({ isAuth, username, children }) => {
  if (!isAuth) {
    return <div className='layout'>{children}</div>;
  }

  return (
    <div className='layout'>
      <Menu />
      <div className='container'>
        <Navigation username={username} />
        <Fragment>{children}</Fragment>
      </div>
    </div>
  );
};

export default LayoutWrapper;
