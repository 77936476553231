import * as types from '~/constants/actionTypes';
import { fromJS } from 'immutable';
import { reportState } from './initState';

export default function reducer(report = reportState, { type, payload }) {
  switch (type) {
    case types.GET_REPORT_SUCCESS:
      const { docs, ...pagination } = payload.records;
      return report.merge(fromJS({ docs, pagination }));
    case types.GET_REPORT_ERROR:
    case types.GET_REPORT:
    default:
      return report;
  }
}
