import { createEpicMiddleware } from 'redux-observable';
import { compose, applyMiddleware, createStore } from 'redux';
import rootEpic from '~/epics';
import rootReducer from '~/reducers';
import { authTokenMiddleware } from '~/middleware/authTokenMiddleware';

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const middleware = [authTokenMiddleware, epicMiddleware];
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

const store = configureStore();

export default store;
