import React, { Fragment } from 'react';
import Button from '../Button';
import './style.scss';

const Dialog = ({ open, title, subtitle, onConfirm, children, onClose }) => {

  if(!open) return <Fragment />;

  return (
    <div className='dialog-container'>
      <div className='dialog-mask' onClick={onClose}></div>
      <div className='dialog'>
        <div className='dialog-title'>{title}</div>
        <div className='dialog-subtitle'>{subtitle}</div>
        <div className='dialog-content'>{children}</div>
        <div className='dialog-footer'>
          <Button text='取消' onClick={onClose} />
          <Button text='確認' onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default Dialog;
