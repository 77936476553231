import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default ({ isAuth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          return <Redirect to={{ pathname: '/' }} />;
        }
        return <Component {...props} isAuth={isAuth} />;
      }}
    />
  );
};
