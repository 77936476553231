import * as types from '~/constants/actionTypes';

export const loginAction = payload => ({
  type: types.LOGIN,
  payload
});

export const logoutAction = payload => ({
  type: types.LOGOUT,
  payload,
})
