import React, { useState, Fragment } from 'react';
import Input from '~/components/Input';
import Button from '~/components/Button';
import packageConfig from '../../../package.json';
import './style.scss';

const { version } = packageConfig;

const onLoginClick = (handleLogin, payload) => () => {
  handleLogin(payload);
};

const onChange = setter => ({  value }) => {
  setter(value);
};

export default ({ handleLogin }) => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const onClick = onLoginClick(handleLogin, { account, password });

  return (
    <Fragment>
      <div className='login-container'>
        <div className='login-panel'>
          <div className='login-logo-box'>
            <div className='login-logo' />
          </div>
          <div className='login-inputs'>
            <Input
              label='帳號'
              placeholder='請輸入帳號'
              onChange={onChange(setAccount)}
            />
            <Input
              label='密碼'
              type='password'
              placeholder='請輸入密碼'
              onChange={onChange(setPassword)}
            />
            <Button text='登入' onClick={onClick} />
          </div>
        </div>
      </div>
      <div className='login-footer'>
        <h6>{version}</h6>
      </div>
    </Fragment>
  );
};
