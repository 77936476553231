import qs from 'querystring';

export const getOptions = (url, customHeaders = {}, payload) => ({
  url: `${url}?${qs.stringify(payload)}`,
  method: 'GET',
  headers: {
    ...customHeaders,
    'Content-Type': 'application/json',
    'rxjs-custom-header': 'Rxjs',
  },
});

export const postOptions = (
  url,
  customHeaders = {},
  payload,
  method = 'POST'
) => ({
  url,
  method,
  headers: {
    ...customHeaders,
    'Content-Type': 'application/json',
    'rxjs-custom-header': 'Rxjs',
  },
  body: JSON.stringify(payload),
});
