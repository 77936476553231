import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './style.scss';
import Icon from '../Icon';

const MenuItem = ({ content, to, icon, watchedMenu, setWatchMenu }) => {
  return (
    <Link to={to} onClick={() => setWatchMenu(to)}>
      <div
        className={classnames('menu-item', {
          active: watchedMenu === to,
        })}>
        <Icon icon={icon}/>
        <span>{content}</span>
      </div>
    </Link>
  );
};

const Menu = () => {
  const [watchedMenu, setWatchMenu] = useState(window.location.pathname);

  return (
    <div className='menu-bar'>
      <div className='menu-logo'></div>
      <div className='menu-items'>
        <MenuItem
          to='/'
          content='服務概況'
          icon='chalkboard'
          watchedMenu={watchedMenu}
          setWatchMenu={setWatchMenu}
        />
        <MenuItem
          to='/report'
          icon='clipboard-list'
          content='答題列表'
          watchedMenu={watchedMenu}
          setWatchMenu={setWatchMenu}
        />
        <MenuItem
          to='/analytic'
          icon='chart-bar'
          content='分析報表'
          watchedMenu={watchedMenu}
          setWatchMenu={setWatchMenu}
        />
        <MenuItem
          to='/accounts'
          icon='fish'
          content='帳號管理'
          watchedMenu={watchedMenu}
          setWatchMenu={setWatchMenu}
        />
      </div>
    </div>
  );
};

export default Menu;
