import React from 'react';

export default ({label, onChange, ...props}) => {

  const handleOnChange = ({ target: {name, value}}) => {
    onChange({ name, value })
  }

  return (
    <div className='form-group'>
      <label>{label}</label>
      <input className='input' onChange={handleOnChange} {...props} />
    </div>
  )
}
