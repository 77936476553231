import * as types from '~/constants/actionTypes';
import { getReportOption, getVisitDataOption, syncDataOption } from 'apis';
import { handleAuthEpic } from '~/utils/epicHelper';

export const reportEpic = handleAuthEpic(types.GET_REPORT, getReportOption);

export const visitReportEpic = handleAuthEpic(
  types.GET_VISIT_REPORT,
  getVisitDataOption
);

export const syncAnswerEpic = handleAuthEpic(
  types.SYNC_ANSWERS,
  syncDataOption
);
