import * as types from '~/constants/actionTypes';
import isEmpty from 'lodash/isEmpty';

const LOGOUT_STATUS_CODE = [401, 403];

export const authTokenMiddleware = store => next => action => {
  const { payload } = action;

  if(!isEmpty(payload)) {
    const { status } = payload;

    if(LOGOUT_STATUS_CODE.includes(status)) return next({
      type: types.LOGOUT
    })
  }

  return next(action);
};
