import { getOptions, postOptions } from './options';

const { REACT_APP_DOMAIN } = process.env;

export const loginOption = ({ customHeaders, ...payload }) =>
 postOptions(`${REACT_APP_DOMAIN}/auth/login`, customHeaders, payload);

export const getReportOption = ({ customHeaders, ...payload }) =>
 getOptions(`${REACT_APP_DOMAIN}/report`, customHeaders, payload);

export const logoutOption = ({ customerHeaders }) =>
  postOptions(`${REACT_APP_DOMAIN}/auth/logout`, customerHeaders );

export const accountsOption = ({ customHeaders, ...payload }) =>
  getOptions(`${REACT_APP_DOMAIN}/user/list`, customHeaders, payload );

export const updateAccountOption = ({ customHeaders, ...payload }) =>
  postOptions(`${REACT_APP_DOMAIN}/user/update`, customHeaders, payload, 'PUT');

export const getVisitDataOption = ({ customHeaders, ...payload }) =>
  getOptions(`${REACT_APP_DOMAIN}/visit/report`, customHeaders, payload)

export const syncDataOption = ({ customHeaders }) =>
  postOptions(`${REACT_APP_DOMAIN}/report/sync`, customHeaders, {})