import React from 'react';
import './style.scss'

const PanelBody = ({ children }) => {
  return (
    <div className='panel-body'>
      {children}
    </div>
  )
}

export default PanelBody;
