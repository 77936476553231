import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';

const successAction = (type, payload) => ({
  type: `${type}_SUCCESS`,
  payload,
});

const errAction = (type, payload) => ({
  type: `${type}_ERROR`,
  payload: {
    level: 'error',
    message: payload.message,
  },
});

export const handleEpic = (type, resultApi) => action$ =>
  action$.pipe(
    ofType(type),
    mergeMap(({ payload }) =>
      ajax(resultApi(payload)).pipe(
        map(({ response }) => successAction(type, response.data)),
        catchError(err => of(errAction(type, err)))
      )
    )
  );

export const handleAuthEpic = ( type, resultApi ) => (action$, state$) => 
  action$.pipe(
    ofType(type),
    mergeMap(({ payload }) =>
      ajax(
        resultApi({
          customHeaders: { Authorization: `Bearer ${state$.value.auth.get('token')}` },
          ...payload,
        })
      ).pipe(
        map(({ response }) => successAction(type, response.data)),
        catchError(err => of(errAction(type, err)))
      )
    )
  );
