import React from 'react';
import './style.scss';

const TableCell = ({ children }) => {
  return (
    <div className='table-cell'>
      {children}
    </div>
  )
}

export default TableCell;
