import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import * as authEpic from './authEpic';
import * as reportEpic from './reportEpic';
import * as userEpic from './userEpic';

const epicCollection = [authEpic, reportEpic, userEpic];

const epics = epicCollection.reduce((rs, modules) => {
  for(let key in modules) {
    rs.push(modules[key]);
  }
  return rs;
}, [])

const rootEpic = (action$, store$, dependencies) => {
  return combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
};

export default rootEpic;
