import React, { Fragment } from 'react';
import Icon from '../Icon';
import './style.scss';

const RadioLabel = ({ checked }) => {
  return checked ? (
    <Icon icon='circle' className='radio-active' />
  ) : (
    <Icon icon='circle' className='radio-inactive' />
  );
};

const Radio = ({
  label,
  items,
  value: selectedValue,
  name: fieldName,
  onChange,
}) => {
  const handleOnChange = ({ target: { name, value } }) => {
    onChange({ name, value });
  };

  return (
    <div className='form-group'>
      <label>{label}</label>
      <div className='radio-group'>
        {items.map(({ name, value }) => {
          return (
            <Fragment key={`${name}_${value}`}>
              <label>
                <input
                  type='radio'
                  name={fieldName}
                  value={value}
                  onChange={handleOnChange}
                />
                <RadioLabel checked={value.toString() === selectedValue.toString()} />
                {name}
              </label>
            </Fragment>
          );
        })}
        <label></label>
      </div>
    </div>
  );
};

export default Radio;
