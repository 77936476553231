import React from 'react';

const InfoCard = ({ title, subtitle, content, type, icon }) => {
  return (
    <div className={`info-card ${type}`}>
      <div className={`info-icon ${type}`}>{icon}</div>
      <div className='info-text'>
        <div className='info-title'>{title}</div>
        <div className='info-subtitle'>{subtitle}</div>
        <div className='info-content'>{content}</div>
      </div>
    </div>
  );
};

export default InfoCard;
