import { connect } from 'react-redux';
import AnalyticScreen from './view';

const mapStateToProps = ({ report, auth }) => ({
  token: auth.get('token'),
  records: report.get('docs'),
  pagination: report.get('pagination').toJS(),
});

const mapDispatchToProps = dispatch => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticScreen);
