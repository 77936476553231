import { connect } from 'react-redux';
import LoginScreen from './view';
import { loginAction } from '~/actions/authActions';

const mapStateToProps = ({ account }) => ({

});

const mapDispatchToProps = dispatch => ({
  handleLogin: payload => {
    dispatch(loginAction(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
