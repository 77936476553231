import { fromJS } from 'immutable';
import { getLoginUser } from '~/store/localStorage';

export const authState = fromJS({
  token: '',
  account: '',
  ...getLoginUser()
});

export const reportState = fromJS({
  docs: [],
  pagination: {}
});

export const accountState = fromJS({
  docs: [],
  pagination: {}
});

export const dashboardState = fromJS({
  today: 0,
  yesterday: 0,
  total: 0,
  visit: 0,
})

export default {
  auth: authState,
  report: reportState,
  account: accountState,
}