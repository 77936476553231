import React, { Fragment, useEffect, useState } from 'react';
import Container from '~/components/Container';
import Panel from '~/components/Panels/Panel';
import Table from '~/components/Table/Table';
import TableBody from '~/components/Table/TableBody';
import PanelBody from '~/components/Panels/PanelBody';
import Pagination from '~/components/Pagination';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import Input from '~/components/Input';
import Radio from '~/components/Radio';
import Dialog from '~/components/Dialog';
import LabelText from '~/components/LabelText';

const ACTIVE_ITEMS = [
  { name: '啟用', value: 1 },
  { name: '停用', value: 0 },
];

const handleDialogOpen = (setOpen, setSelectItem) => selectedItem => () => {
  setOpen(true);
  setSelectItem(selectedItem);
};

const handleDialogClose = (setOpen, setSelectItem) => () => {
  setOpen(false);
  setSelectItem({});
};

const AccountsScreen = ({
  records,
  pagination,
  handleGetAccounts,
  handleUpdateAccount,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectItem] = useState({});

  useEffect(() => {
    handleGetAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDialogOpen = handleDialogOpen(setOpen, setSelectItem);

  const onDialogClose = handleDialogClose(setOpen, setSelectItem);

  const onPageChange = page => {
    handleGetAccounts({ page });
  };

  const onChange = ({ name, value }) => {
    setSelectItem(state => ({ ...state, [name]: value }));
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        title='編輯帳號'
        onClose={onDialogClose}
        onConfirm={() => {
          handleUpdateAccount(selectedItem);
          onDialogClose();
        }}>
        <LabelText label='帳號' value={selectedItem.account} />
        <Input
          name='username'
          label='使用者名稱'
          placeholder='請輸入使用者名稱'
          value={selectedItem.username}
          onChange={onChange}
        />
        <Radio
          name='status'
          label='啟用狀態'
          items={ACTIVE_ITEMS}
          value={selectedItem.status}
          onChange={onChange}
        />
      </Dialog>
      <Container>
        <Panel>
          <PanelBody>
            <Table>
              <TableHeader />
              <TableBody>
                {records.map(account => (
                  <TableRow
                    key={account.get('account')}
                    account={account}
                    onDialogOpen={onDialogOpen(account.toJS())}
                  />
                ))}
              </TableBody>
            </Table>
          </PanelBody>
          <Pagination {...pagination} onChange={onPageChange} />
        </Panel>
      </Container>
    </Fragment>
  );
};

export default AccountsScreen;
