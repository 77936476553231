import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import PublicRoute from '~/components/PublicRoute';
import PrivateRoute from '~/components/PrivateRoute';
import LoginScreen from '~/screen/LoginScreen';
import ErrorScreen from '~/screen/ErrorScreen';
import ReportScreen from '~/screen/ReportScreen';
import AnalyticScreen from '~/screen/AnalyticScreen';
import AccountsScreen from '~/screen/AccountsScreen';
import DashboardScreen from '~/screen/DashboardScreen';
import LayoutWrapper from '~/components/LayoutWrapper';
import '../../App.scss';

export default ({ isAuth, username }) => {
  const [, setInitApp] = useState(false);

  useEffect(() => {
    setInitApp(true);
  }, [setInitApp]);

  return (
    <Router>
      <LayoutWrapper isAuth={isAuth} username={username}>
        <Switch>
          <PublicRoute
            exact
            isAuth={isAuth}
            path='/login'
            component={LoginScreen}
          />
          <PrivateRoute
            exact
            path='/'
            isAuth={isAuth}
            component={DashboardScreen}
          />
          <PrivateRoute
            exact
            path='/analytic'
            isAuth={isAuth}
            component={AnalyticScreen}
          />
          <PrivateRoute
            exact
            path='/report'
            isAuth={isAuth}
            component={ReportScreen}
          />
          <PrivateRoute
            exact
            path='/accounts'
            isAuth={isAuth}
            component={AccountsScreen}
          />
          <Route path='/404' exact component={ErrorScreen} />
          <Redirect from='*' to='/404' />
        </Switch>
      </LayoutWrapper>
    </Router>
  );
};
