import React from 'react';
import TableHeader from '~/components/Table/TableHeader';
import TableCell from '~/components/Table/TableCell';

export default () => {
  return (
    <TableHeader>
      <TableCell>帳號</TableCell>
      <TableCell>使用者名稱</TableCell>
      <TableCell>啟用狀態</TableCell>
      <TableCell>建立日期</TableCell>
      <TableCell>操作</TableCell>
    </TableHeader>
  );
};
