import * as types from '~/constants/actionTypes';
import { fromJS } from 'immutable';
import { accountState } from './initState';

const updateAccounts = (account, payload) => {
  const index = account.get('docs').findIndex(account => account.get('uuid') === payload.uuid);
  return account.updateIn(['docs', index], () => fromJS(payload));
}

export default function reducer(account = accountState, { type, payload }) {
  switch (type) {
    case types.GET_ACCOUNTS_SUCCESS:
      const { docs, ...pagination } = payload.records;
      return account.merge(fromJS({ docs, pagination }));
    case types.UPDATE_ACCOUNT_SUCCESS:
      return updateAccounts(account, payload.data);
    case types.UPDATE_ACCOUNT_ERROR:
    case types.UPDATE_ACCOUNT:
    case types.GET_ACCOUNTS_ERROR:
    case types.GET_ACCOUNTS:
    default:
      return account;
  }
}
