import * as types from '~/constants/actionTypes';

export const getAccountsAction = payload => ({
  type: types.GET_ACCOUNTS,
  payload,
});

export const updateAccountAction = payload => ({
  type: types.UPDATE_ACCOUNT,
  payload,
})
