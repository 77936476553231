import React from 'react';
import Chart from '~/components/Chart';

const labels = ['5月5日', '5月6日', '5月7日', '5月8日', '5月9日', '5月10日'];
const data = {
  labels: labels,
  datasets: [
    {
      label: '近一週網站人次',
      backgroundColor: '#4AA9DB',
      borderColor: '#4AA9DB',
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};

const config = {
  type: 'line',
  data,
  options: {},
};

const AnswerChart = () => {
  return (
    <div className='chart-container'>
      <Chart name='visit' config={config} />
    </div>
  );
};

export default AnswerChart;
